//let Instafeed = require("instafeed.js");
import $ from 'jquery';
import slick from 'slick-carousel';
let Masonry = require('masonry-layout');
let imagesLoaded = require('imagesloaded');
import SmoothScroll from 'smooth-scroll';
import AOS from 'aos';
import {
  Calendar
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import 'url-search-params-polyfill';
import tippy from 'tippy.js'

class App {

  constructor() {

  }

  init() {
    console.info('App Initialized');
  }

  hamburger(element) {
    this.element = element
    // Look for .hamburger
    let hamburger = document.querySelector(element);
    // On click
    hamburger.addEventListener("click", function() {
      // Toggle class "is-active"
      hamburger.classList.toggle("is-active");
      // Do something else, like open/close menu
    });
  }
  navigationAccordion(element) {
    this.element = element
    // Look for .hamburger
    let navItems = document.querySelectorAll(element);
    // On click
    navItems.forEach(function(navItem) {
      navItem.addEventListener("click", function(e) {
        // Toggle class "is-active"
        e.preventDefault();
        navItem.classList.toggle("is-active");
        navItem.parentNode.classList.toggle("is-active");
        // Do something else, like open/close menu
      });
    })
  }
  accordion(container, heading, content) {
    this.container = container;
    this.heading = heading;
    this.content = content;
    // Look for .hamburger
    let accordions = document.querySelectorAll(container);
    // On click
    accordions.forEach(function(accordion) {
      accordion.querySelector(heading).addEventListener("click", function() {
        // Toggle class "is-active"
        let accordionHeading = accordion.querySelector(heading);
        let accordionContent = accordion.querySelector(content);
        accordion.classList.toggle("is-active");
        accordionHeading.classList.toggle("is-active");
        accordionContent.classList.toggle("is-active");

        if (accordionContent.style.maxHeight) {
          accordionContent.style.maxHeight = null;
        } else {
          accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`;
        }
      });
    })
  }
  // instagram(){
  // 	var feed = new Instafeed({
  // 		get: 'user',
  //    	userId: '1320723658',
  //    	accessToken: '1320723658.76f501d.a94b0db46bd54815baf4b83c26603763',
  //    	sortBy: 'most-recent',
  //    	resolution: 'standard_resolution',
  //    	template: `
  //    	<li class="instagram__feed--slide col-md-3">
  //    		<a class="instagram__feed--link inline-block" href="{{link}}" target="_blank">
  //    			<img class="instagram__feed--image" src="{{image}}" />
  //    			<div class="instagram__feed--hover">
  //     			<div class="instagram__feed--hoverContent">
  //     				<span class="instagram__feed--hoverLikes"><i class="far fa-heart"></i> {{likes}}</span>
  //     				<span class="instagram__feed--hoverComments"><i class="far fa-comment"></i> {{comments}}</span>
  //     			</div>
  //    			</div>
  //    		</a>
  //    		</li>
  //    	`,
  //    	before: function(){

  //    	},
  //    	success: function(data){
  //    		//localStorage.setItem('local_instafeed', JSON.stringify(data.data));
  //    		//console.log(data);
  //    	},
  //    	after: function(){
  //    		 const instagramCarousel = $('#instafeed');
  //    		 let instagramLoading = document.querySelector('.instagram__feed--loading');
  //    		 instagramLoading.style.display = 'none';
  // 	    	instagramCarousel.slick({
  //          centerMode: false,
  //          infinite: false,
  //          slidesToShow: 4,
  //          slidesToScroll: 1,
  //          dots: true,
  //          rows: 0,
  //          arrows: false,
  //          responsive: [
  //            {
  //              breakpoint: 992,
  //              settings: {
  //                slidesToShow: 2,
  //                  centerMode: false,
  //                  slidesToScroll: 1
  //                }
  //            },
  //            // {
  //            //   breakpoint: 650,
  //            //   settings: {
  //            //     slidesToShow: 1,
  //            //       centerMode: false,
  //            //       slidesToScroll: 1
  //            //   }
  //            // }
  //          ]
  //        });
  //    	}
  // 	});
  // 	feed.run();

  // }
  instagramFeed() {
    const instagramCarousel = $('#instagramFeed');
    let instagramLoading = document.querySelector('.instagram__feed--loading');
    instagramLoading.style.display = 'none';
    instagramCarousel.slick({
      centerMode: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: true,
      rows: 0,
      arrows: false,
      responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            centerMode: false,
            slidesToScroll: 1
          }
        },
        // {
        //   breakpoint: 650,
        //   settings: {
        //     slidesToShow: 1,
        //       centerMode: false,
        //       slidesToScroll: 1
        //   }
        // }
      ]
    });
  }
  current_exhibitions() {
    let grid_loader = document.querySelector('.js-masonry-grid--loader');
    let grid_top = document.querySelector('.js-masonry-grid--top');
    let grid_bottom = document.querySelector('.js-masonry-grid--bottom');
    if (grid_top && grid_loader) {
      grid_top.style.opacity = 0;
      imagesLoaded('.js-masonry-grid--top', function() {
        grid_loader.style.display = 'none';
        grid_top.style.opacity = 1;
        var grid__top = new Masonry('.js-masonry-grid--top', {
          itemSelector: '.js-masonry-grid-item',
          columnWidth: '.js-masonry-grid-sizer',
          percentPosition: true,
          horizontalOrder: true,
          originTop: false
        });
      });
    }
    if (grid_bottom && grid_loader) {
      grid_bottom.style.opacity = 0;
      imagesLoaded('.js-masonry-grid--bottom', function() {
        grid_loader.style.display = 'none';
        grid_bottom.style.opacity = 1;
        // 	var grid__bottom = new Masonry( '.masonry-grid--bottom', {
        // 	itemSelector: '.masonry-grid-item',
        // 	//fitWidth: true,
        //   itemSelector: '.js-masonry-grid-item',
        //  	//columnWidth: '.js-masonry-grid-sizer',
        //   //percentPosition: true,
        //   horizontalOrder: true,
        //   originTop: true
        // });
        var grid__bottom = new Masonry('.js-masonry-grid--bottom', {
          itemSelector: '.js-masonry-grid-item',
          columnWidth: '.js-masonry-grid-sizer',
          percentPosition: true,
          horizontalOrder: true,
          originTop: true,
        });
      });
    }
    const currentExhibitions = $('#current-exhibitions');
    currentExhibitions.slick({
      centerMode: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      rows: true,
      arrows: true,
      appendArrows: '#current-exhibitions__arrows'
    });
  }
  exhibitions_slider(element) {
    const currentExhibitions = $(element);
    currentExhibitions.slick({
      centerMode: false,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      rows: true,
      arrows: true,
      responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            centerMode: false,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            slidesToScroll: 1
          }
        }
      ]
    });
    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
      $(element).slick('refresh');
      e.target // newly activated tab
      e.relatedTarget // previous active tab
    });
  }

  exhibition_slider(element) {
    const currentExhibitions = $(element);
    currentExhibitions.slick({
      centerMode: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      rows: true,
      arrows: true,
      speed: 500,
      fade: true,
      cssEase: 'linear'
    });
  }
  homepage_slider(element) {
    const homepageImages = $(element);
    homepageImages.slick({
      centerMode: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      rows: true,
      arrows: true,
      speed: 500,
      fade: true,
      autoplay: true,
      autoplaySpeed: '5000',
      cssEase: 'linear'
    });
  }
  rent_slider(element) {
    const rentImages = $(element);
    rentImages.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      infinite: true,
      // dots: true,
      arrows: true,
      // variableWidth: true,
      adaptiveHeight: true
    });
  }
  split_slider(element) {
    const homepageSplitImages = $(element);
    homepageSplitImages.slick({
      centerMode: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      rows: true,
      arrows: true,
      speed: 500,
      fade: true,
      //autoplay: true,
      autoplaySpeed: '5000',
      cssEase: 'linear'
    });
  }
  member_events_slider(element) {
    const memberEvents = $(element);
    memberEvents.slick({
      centerMode: false,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      rows: true,
      arrows: true,
      speed: 500,
      cssEase: 'linear',
      responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            centerMode: false,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
  aos() {
    AOS.init({
      disable: 'mobile',
      once: true
    });
  }
  related_events_modal() {
    let relatedEventsParents = document.querySelectorAll('.js-related-events-parent');
    let modal_title = document.querySelector('.js-modal-title');
    relatedEventsParents.forEach(function(item) {
      item.querySelector('.js-related-events-parent-link').addEventListener('click', function(e) {
        let related_event_children = document.querySelectorAll('.js-related-events-children');
        let related_event_children_active = document.querySelector(`.js-related-events-children[data-event-type-parent="${item.id}"]`);
        e.preventDefault();
        //console.log(item.id);
        modal_title.innerText = item.innerText;
        related_event_children.forEach(function(item) {
          item.style.display = 'none';
          item.style.visibility = 'hidden';
        });
        related_event_children_active.style.display = 'block';
        related_event_children_active.style.visibility = 'visible';
      });
    });
  }
  smooth_scroll() {
    let scroll = new SmoothScroll('a[data-scroll]');
    let scrollSignup = new SmoothScroll('a[href="#signup"]');
  }
  search() {
    $('.control').click(function() {
      $('body').addClass('search-active');
      $('.input-search').focus();
    });

    $('.icon-close').click(function() {
      $('body').removeClass('search-active');
    });
  }
  tippy_init() {
    tippy('[data-tippy-content]');
  }
  bootstrap_tabs(elementId) {
    let url_hash = window.location.hash;
    let tabsElement = document.getElementById(elementId);
    if (url_hash && tabsElement && $(`#${elementId} a[href="${url_hash}"]`)) {
      $(`#${elementId} a[href="${url_hash}"]`).tab('show');
      if (history.pushState) {
        history.pushState(null, null, `${url_hash}-open`);
      } else {
        location.hash = `${url_hash}-open`;
      }
    }
  }
}

export default App;