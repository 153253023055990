<template>
	<div class="events-calendar__container" :class="{ 'is-loading': this.progress < 25 }">
		<div class="progress" :class="{ 'fade-out': this.progress >= 100 }" style="display: none;">
				<!-- <div class="progress-bar bg-color-green-dark" role="progressbar" :style="{width: progress + '%'}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"><p class="mb-0 text-white">Events Added: {{this.events.length}}</p></div> -->
				<div class="progress-bar bg-color-green-dark" role="progressbar" :style="{width: progress + '%'}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"><p class="mb-0 text-white">Loading Events...</p></div>
		</div>
		<div class="events-calendar__row">
			<div class="events-calendar__col">
				<div v-if="this.progress < 25" class="events--loading text-center">
        	<i class="fa-4x fas fa-circle-notch fa-spin"></i>
      	</div>
      	<!-- <div v-if="this.currentFilter" class="js-events-current-filter">
      		<p>{{this.currentFilter}}</p>
      	</div> -->
				<div id="full-calendar" class="events-calendar"></div>
			</div>
		</div>
	</div>
</template>
<script>
	import { Calendar } from '@fullcalendar/core';
	import dayGridPlugin from '@fullcalendar/daygrid';
	import timeGridPlugin from '@fullcalendar/timegrid';
	import listPlugin from '@fullcalendar/list';
	import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
	import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
	import bootstrapPlugin from '@fullcalendar/bootstrap';
	import axios from 'axios';
	import moment from 'moment';
	import tippy from 'tippy.js';
	export default{
		name: 'events-calendar',
		data(){
			return{
				events: [],
				resources: [],
				currentFilter: '',
				addedAllResources: false,
				eventCalendar: null, // fullcalendar instnace when mounted
				eventsLoading: true,
				resourcesLoading: true,
				progress: 0,
			}
		},
		methods: {
			//This is function to activate the Fullcandar api
			renderCalendar(calendarElement){
			  let calendar = new Calendar(calendarElement, {
			  	plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin, resourceTimeGridPlugin, resourceTimelinePlugin ],
			    timeZone: 'UTC',
			    defaultView: document.getElementById('events-calendar').dataset.calendarDefaultView,
			    themeSystem: 'bootstrap',
			    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
			    //refetchResourcesOnNavigate: true,
			    //resources: this.resources,
			    resources: function(fetchInfo, successCallback, failureCallback) {
			    	axios.get('/api/v2/event_resources.json')
						.then(response => {
							console.log('%cResources Full Calendar Response: ' + response.status,"color: yellow; font-style: italic; font-weight: 500;padding: 2px; border: 1px solid yellow;");
								successCallback(response.data.resources);
								calendar.rerenderResources();
								console.log(calendar.getResources());
		        })
		        .catch(error => {
		          console.log('%cResources axios error: '+ error,"color: yellow; font-style: italic; font-weight: 500;padding: 2px; border: 1px solid red;");
		          	failureCallback(error)
		        })
				  },
			    views: {
				    listMonth: {
				      // other view-specific options here
				    },
				    listMonthGrid: {
				    	type: 'listMonth',
				    	//type: 'basicWeek',
				    }
				  },
			    customButtons: {
				    adultPrograms: {
				      text: 'Adult Programs',
				      click: (e) => {
				      	console.log(e.target);
				      	this.filterEventsByResourceId('adult-programs');
				      	this.removeAllIsActive('adult-programs');
				      }
				    },
				    childrenAndFamily: {
				      text: 'Children & Family',
				      click: (e) => {
				      	console.log(e.target);
				      	this.filterEventsByResourceId('family-programs');
				      	this.removeAllIsActive('family-programs');
				      }
				    },
				    socialEvents: {
				      text: 'Social Events',
				      click: (e) => {
				      	console.log(e.target);
				      	this.filterEventsByResourceId('social-events');
				      	this.removeAllIsActive('social-events');
				      }
				    },
				    studioClasses: {
				      text: 'Studio Classes',
				      click: (e) => {
				      	console.log(e.target);
				      	this.filterEventsByResourceId('studio-classes');
				      	this.removeAllIsActive('studio-classes');
				      }
				    },
				    all: {
				      text: 'All',
				      click: (e) => {
				      	console.log(e.target);
				      	this.addAllEventSources(this.events)
				      	this.removeAllIsActive();
				      }
				    }
				  },
				  header: {
			      left: 'title',
			      center: 'adultPrograms,childrenAndFamily,socialEvents,studioClasses,all',
			      right: 'prev,next today'
			    },
			    height:'auto',
			    // fullcalendar method to edit event html
			    eventRender: function(info) {
			    	// change html on listMonthGrid view
						if (info.view.type === 'listMonthGrid'){
							console.log(info);
							var eventElementClassList = info.el.classList;
							var eventFeaturedImageSrc = info.event.extendedProps.eventFeaturedImage;
							var eventTitle = info.event.title;
							var eventUrl = info.event.url;
							var eventStartTime = info.event.start;
							var eventEndTime = info.event.end;
							if (eventFeaturedImageSrc){
								const elementMarkup = `
									<img class="img-fluid" src="${eventFeaturedImageSrc}">
								`;
								let elementMarkupElement = document.createElement('div');
								elementMarkupElement.className = 'eventFeaturedImageSrc';
								elementMarkupElement.innerHTML = elementMarkup;
								//info.el.innerHTML = elementMarkup;
								info.el.append(elementMarkupElement);
							}
							if (eventStartTime){
								var eventStartTime = moment(eventStartTime).format("dddd, MMMM Do YYYY");

								const eventStartTimeMarkup = `
									<p class="mb-0">${eventStartTime}</p>
								`;
								let eventStartTimeMarkupElement = document.createElement('div');
								eventStartTimeMarkupElement.className = 'eventStartTime';
								eventStartTimeMarkupElement.innerHTML = eventStartTimeMarkup;
								info.el.append(eventStartTimeMarkupElement);
							}
						}
						if (info.view.type === 'dayGridMonth'){
							tippy(info.el,{
								theme: 'dark',
								placement: 'top',
								content: `
								<div>
									  <dd><i>${ info.event.title }</i></dd>
											<div class="event-dates">
												<time class="date text-light font-weight-light" :datetime="${moment.utc(info.event.start).format("MMM Do YYYY")}">
													<i class="far fa-calendar-alt"></i> <em>${moment.utc(info.event.start).format("MMM Do YYYY")}</em>
												</time><br>
												<time class="date text-light font-weight-light" :datetime="${moment.utc(info.event.start).format("LT")} - ${moment.utc(info.event.end).format("LT")}">
													<em>${moment.utc(info.event.start).format("LT")} - ${moment.utc(info.event.end).format("LT")}</em>
												</time>
											</div>
								</div>`
							});
					  }
				  },
				});
				// initial full cal rendering
		  	calendar.render();
		  	console.log('CALENDAR RENDERED');
		  	// Set eventCalendar to fullcalendar instance
		  	this.eventCalendar = calendar;
		  	// Trigger the sidebar events function for single filtering
		  	this.enableSingleEventFilterSidebar();
		  	// Trigger ability to change event views
		  	this.enableCalendarViewButtons();

		  	if (window.innerWidth < 992) {
					//calendar.changeView('listMonth');
				}
			},
			// Once events are refreshed lets add them to the calendar
			addEventSource(events){
			  this.eventCalendar.addEventSource( events );
			  console.log('%cadded inititial event sources to calendar',"color: yellow; font-style: italic; background-color: blue;padding: 2px");
			},
			// Once events are refreshed lets add them to the calendar
			addMoreEvents(events){
				this.eventCalendar.addEventSource( events );
				console.log('%cadded more event sources to calendar',"color: yellow; font-style: italic; background-color: blue;padding: 2px");
			},
			// Add Resources helper function
			addResource(resources){
			  this.eventCalendar.addResource( resources );
			  console.log('%cadded inititial event sources to calendar',"color: yellow; font-style: italic; background-color: blue;padding: 2px");
			},
			// This is a visual effect for the events pagination while the api is fetching events
			addProgress: function(){
	  		this.progress += 100/this.progress;
	  	},
	  	// when calendar is rendered this will let users toggle between views
	  	enableCalendarViewButtons(){
	  		let calendarViewButtons = document.querySelectorAll(".js-change-calendar-view");
	  		calendarViewButtons.forEach(item => {
	  			item.addEventListener('click', (e) => {
	  				let calendarView = e.target.dataset.calendarView;
	  				this.eventCalendar.changeView(`${calendarView}`);
	  			});
	  		})
	  	},
	  	// helper function to add all event sources
	  	addAllEventSources(events){
	  		this.removeAllEventSources();
				this.eventCalendar.addEventSource(this.events);
				this.eventCalendar.render();
			  console.log('%cadded all event sources from state to calendar',"color: yellow; font-style: italic; background-color: blue;padding: 2px");
			},
			// helper function to remove all event sources
	  	removeAllEventSources(){
	  		let allEventSources = this.eventCalendar.getEventSources();
	  		allEventSources.forEach(source => {
	  			source.remove();
	  		});
	  	},
	  	// This is a helper function to remove the active class when changes filters
	  	removeAllIsActive(element){
	  		let allSidebarItems = document.querySelectorAll(`.js-enable-single-event-filter.is-active:not(${element})`);
	  		allSidebarItems.forEach((item) => {
					item.classList.remove("is-active");
	  		});
	  	},
	  	// This is a helper function to filter by an eventType slug
	  	filterEventsByEventTypeSlug(eventTypeSlug){
	  		var eventType = this.eventCalendar.getResourceById(eventTypeSlug);
				var eventTypeEvents = eventType.getEvents();
				this.removeAllEventSources();
				var filteredEvents = this.events.filter(event => {
					return event.eventTypeSlug === eventTypeSlug;
				});
				console.log(filteredEvents);
				this.addEventSource(filteredEvents);
	  	},
	  	// This is a helper function to filter by an array of event resource ids/slugs
	  	filterEventsByResourceId(resourceId){

	  		var eventType = this.eventCalendar.getResourceById(resourceId);
	  		console.log(eventType);
				var eventTypeEvents = eventType.getEvents();

				this.removeAllEventSources();

				//Filter the event against array of resource ids/slugs
				var filteredEvents = this.events.filter(event => {
					if (event.resourceIds){
						return event.resourceIds.some(id => resourceId.includes(id));
					}
				});

				console.table(filteredEvents);
				this.addEventSource(filteredEvents);
				this.currentFilter = eventType.title;
	  	},
	  	// This function is to filter single event types on click of the sidebar buttons & also toggles the is-active classes
	  	enableSingleEventFilterSidebar(element){

	  		//get all items in the sidebar with this classname
	  		let allSidebarItems = document.querySelectorAll('.js-enable-single-event-filter');

	  		allSidebarItems.forEach((item) => {

	  			//when you click the sidebar item..
	  			item.addEventListener('click',(e) => {

	  				e.preventDefault();

	  				let event_type_element = e.target;
	  				let event_type_target = e.target.dataset.eventTypeTarget;

	  				// remove all is-active classnames in case the user tries to go back to all events or switch filters
	  				this.removeAllIsActive(`.js-${event_type_target}`);

	  				// if element already has is-active than we need to remove the classname because the user is trying to go back to all events or switching filters
	  				if ( event_type_element.classList.contains("is-active") ){

	  					this.addAllEventSources();

	  				} else {

	  					this.filterEventsByResourceId(event_type_target);

	  				}

	  				//lastly call the toggle method for the is-active classname
						event_type_element.classList.toggle("is-active");
	  			})
	  		})
	  	},
	  	// Trigger ability to filter events by query string
	  	enableSingleEventFilterQueryString(){
	  		let urlParams = new URLSearchParams(window.location.search);
	  		if (urlParams.has('filter')){
	  			this.filterEventsByResourceId(urlParams.get('filter'));
	  			console.log('?filter used');
				}
	  	},
	  	// helper function to fetch events from api with axios
			fetchEvents(endpoint){
				console.log('%cEvents Fetched',"color: green; border: 1px solid yellow;padding: 2px; font-weight: 700;");
				//Get events and assign to this.events to populate fullcalendar
				axios.get(endpoint)
				.then(response => {
							console.log('%cEvents axios response: ' + response.status,"color: yellow; font-style: italic; font-weight: 500;padding: 2px; border: 1px solid yellow;");
							//To make this load faster, lets use the pagination to load events async
							//First we need to check if the component state of events is empty
							//If it is, update events state so we have an initial reference of state
							if (this.events === undefined || this.events.length == 0) {
								//add events to this.events state
								this.events = response.data.events;
								//add events to fullcalendar
								this.addEventSource(this.events);
								if (response.data.meta.pagination.links.next){
									this.fetchEvents(response.data.meta.pagination.links.next)
									console.log('%cPagination ' + response.data.meta.pagination.links.next,"color: white; padding: 2px")
								}
							//Once the initial state is populated we can add more events and keep running the fetch request until there is no more pagination
							} else {
								//concat events to this.events state
								this.events = this.events.concat(response.data.events);
								//concat events to fullcalendar
								this.addMoreEvents(response.data.events);
								if (response.data.meta.pagination.links.next){
	          			this.fetchEvents(response.data.meta.pagination.links.next)
	          			console.log('%cPagination ' + response.data.meta.pagination.links.next,"color: white; padding: 2px")
	          		}
							}
							//if the api has pagination lets update the progress bar to show when its done loading/fetching
							if (response.data.meta.pagination.total_pages){
								let currentPageProgress = (100 / response.data.meta.pagination.total_pages);
									this.progress = this.progress + currentPageProgress;
							}
							//turn off event loading visual when the api is done being fetched
	          	this.eventsLoading = false;
	        })
	        .catch(error => {
	          console.log('%cEvents axios error: ' + error,"color: yellow; font-style: italic; font-weight: 500;padding: 2px; border: 1px solid red;");
	        })
			},
			// helper function to fetch resources from api with axios
			fetchResources(endpoint){
				console.log('%cResources Fetched',"color: green; border: 1px solid yellow;padding: 2px; font-weight: 700;");
				//Get events and assign to this.events to populate fullcalendar
				axios.get(endpoint)
				.then(response => {
							console.log('%cResources axios response: ' + response.status,"color: yellow; font-style: italic; font-weight: 500;padding: 2px; border: 1px solid yellow;");
							//First we need to check if the component state of resoureces is empty
							//If it is, update resoureces state so we have an initial reference of state
							if (this.resources === undefined || this.resources.length == 0) {
								this.resources = response.data.resources;//add resoureces to this.resources state
								//this.addResource(this.resources);
							}
							//turn off resoureces loading visual when the api is done being fetched
	          	this.resourcesLoading = false;
	          	//this.renderCalendar(document.getElementById('full-calendar'));
	        })
	        .catch(error => {
	          console.log('%cResources axios error: ',"color: yellow; font-style: italic; font-weight: 500;padding: 2px; border: 1px solid red;" + error);
	        })
			},
			// helper function to quickly add all resources to calendar from local state
			addAllResources(){
				this.resources.forEach((resource) => {
					this.eventCalendar.addResource(resource);
				});
				console.log("%cAll resources added to Calendar","color: yellow; font-style: italic; background-color: blue;padding: 2px");
				this.eventCalendar.rerenderResources();
				this.addedAllResources = true;
			},
			formatDate(date){
				return moment(date).format("MMM Do YYYY");
			},
			formatTime(date){
				return moment(date).format("LT");
			},
		},
		/*
		* VUE Life Cycle Hooks
		* https://vuejs.org/v2/guide/instance.html
		*/
		beforeCreate() {
			console.log("%cComponent Before Create","color: green; font-weight: 700;padding: 2px");
		},
		created() {
			// run the fetch events method on component create to fetch all events from api
			console.log('%cComponent Created',"color: green; font-weight: 700;padding: 2px");
			//this.fetchResources('/api/v2/event_resources.json');
			this.fetchEvents('/api/v2/events.json');
		},
		beforeMount() {
			console.log('%cComponent Before Mount',"color: green; font-weight: 700;padding: 2px");
		},
		mounted(){
			console.log('%cComponent Mounted',"color: green; font-weight: 700;padding: 2px");
			//Render the calendar on mount
			this.renderCalendar(document.getElementById('full-calendar'));
		},
		updated(){
			console.log('%cComponent Updated',"color: green; font-weight: 700;padding: 2px");
			//var event_6422 = this.eventCalendar.getEventById('6422');

			// all events have been loaded do something
			if (this.progress === 100){
				// Trigger ability to filter events by query string
		  	    this.enableSingleEventFilterQueryString();
		  	    console.log(this.eventCalendar.getResources());
			}
			// resources are done loading lets do something
			if (!this.resourcesLoading && !this.addedAllResources){
				// add all resources to calendar
				// this.addAllResources();
				// console.log(this.eventCalendar.getResources());
			}
		},
	}
</script>