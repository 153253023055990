var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.featuredEvents, function(eventType, index) {
        return _c(
          "div",
          { key: index, staticClass: "split__right--contentListBlock" },
          _vm._l(eventType, function(event, index) {
            return _c(
              "div",
              { key: index },
              [
                event.eventType
                  ? [
                      index === 0
                        ? _c("div", [
                            _c("dt", { staticClass: "event-type" }, [
                              _vm._v(_vm._s(event.eventType))
                            ]),
                            _vm._v(" "),
                            _c("dd", [
                              _c(
                                "a",
                                {
                                  staticClass: "event-title",
                                  attrs: { href: event.url }
                                },
                                [_c("i", [_vm._v(_vm._s(event.title))])]
                              )
                            ]),
                            _vm._v(" "),
                            _c("dd", { staticClass: "dates" }, [
                              _c(
                                "div",
                                { staticClass: "dates--scheduleBlock" },
                                [
                                  _c(
                                    "time",
                                    {
                                      staticClass:
                                        "date dates--scheduleBlockDate",
                                      attrs: {
                                        datetime: _vm.formatDate(event.date)
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "far fa-calendar-alt color-grey-extra-dark"
                                      }),
                                      _vm._v(" "),
                                      _c("em", [
                                        _vm._v(
                                          _vm._s(_vm.formatDate(event.date))
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "time",
                                    {
                                      staticClass:
                                        "date dates--scheduleBlockTime",
                                      attrs: {
                                        datetime: _vm.formatTime(event.start)
                                      }
                                    },
                                    [
                                      _c("em", [
                                        _vm._v(
                                          _vm._s(_vm.formatTime(event.start)) +
                                            " - " +
                                            _vm._s(_vm.formatTime(event.end))
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br")
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            )
          }),
          0
        )
      }),
      _vm._v(" "),
      this.eventsLoading
        ? _c("div", { staticClass: "events--loading text-center" }, [
            _c("i", { staticClass: "fa-6x fas fa-circle-notch fa-spin" })
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }