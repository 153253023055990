var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "events-calendar__container",
      class: { "is-loading": this.progress < 25 }
    },
    [
      _c(
        "div",
        {
          staticClass: "progress",
          class: { "fade-out": this.progress >= 100 },
          staticStyle: { display: "none" }
        },
        [
          _c(
            "div",
            {
              staticClass: "progress-bar bg-color-green-dark",
              style: { width: _vm.progress + "%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "0",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            },
            [
              _c("p", { staticClass: "mb-0 text-white" }, [
                _vm._v("Loading Events...")
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "events-calendar__row" }, [
        _c("div", { staticClass: "events-calendar__col" }, [
          this.progress < 25
            ? _c("div", { staticClass: "events--loading text-center" }, [
                _c("i", { staticClass: "fa-4x fas fa-circle-notch fa-spin" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "events-calendar",
            attrs: { id: "full-calendar" }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }