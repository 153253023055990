<template>
  <div>
    <div
      class="split__right--contentListBlock"
      v-for="(eventType, index) in featuredEvents"
      :key="index"
    >
      <div v-for="(event, index) in eventType" :key="index">
        <template v-if="event.eventType">
          <div v-if="index === 0">
            <dt class="event-type">{{event.eventType}}</dt>
            <dd>
              <a :href="event.url" class="event-title">
                <i>{{ event.title }}</i>
              </a>
            </dd>
            <dd class="dates">
              <div class="dates--scheduleBlock">
                <time class="date dates--scheduleBlockDate" :datetime="formatDate(event.date)">
                  <i class="far fa-calendar-alt color-grey-extra-dark"></i>
                  <em>{{formatDate(event.date)}}</em>
                </time>
                <time class="date dates--scheduleBlockTime" :datetime="formatTime(event.start)">
                  <em>{{formatTime(event.start)}} - {{formatTime(event.end)}}</em>
                </time>
                <br />
              </div>
            </dd>
          </div>
        </template>
      </div>
    </div>
    <div v-if="this.eventsLoading" class="events--loading text-center">
      <i class="fa-6x fas fa-circle-notch fa-spin"></i>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "featured-events",
  data() {
    return {
      events: [],
      eventsLoading: true,
      progress: 0
    };
  },
  computed: {
    featuredEvents: function() {
      return this.groupBy(this.events, "eventType");
    }
  },
  methods: {
    // helper function to fetch events from api with axios
    fetchEvents(endpoint) {
      console.log(
        "%cEvents Fetched",
        "color: green; border: 1px solid yellow;padding: 2px; font-weight: 700;"
      );
      //Get events and assign to this.events to populate fullcalendar
      axios
        .get(endpoint)
        .then(response => {
          console.log(
            "%cEvents axios response: " + response.status,
            "color: yellow; font-style: italic; font-weight: 500;padding: 2px; border: 1px solid yellow;"
          );
          //To make this load faster, lets use the pagination to load events async
          //First we need to check if the component state of events is empty
          //If it is, update events state so we have an initial reference of state
          if (this.events === undefined || this.events.length == 0) {
            //add events to this.events state
            this.events = response.data.events;

            if (response.data.meta.pagination.links.next) {
              this.fetchEvents(response.data.meta.pagination.links.next);
              console.log(
                "%cPagination " + response.data.meta.pagination.links.next,
                "color: white; padding: 2px"
              );
            }
            //Once the initial state is populated we can add more events and keep running the fetch request until there is no more pagination
          } else {
            //concat events to this.events state
            this.events = this.events.concat(response.data.events);

            if (response.data.meta.pagination.links.next) {
              this.fetchEvents(response.data.meta.pagination.links.next);
              console.log(
                "%cPagination " + response.data.meta.pagination.links.next,
                "color: white; padding: 2px"
              );
            }
          }
          //if the api has pagination lets update the progress bar to show when its done loading/fetching
          if (response.data.meta.pagination.total_pages) {
            let currentPageProgress =
              100 / response.data.meta.pagination.total_pages;
            this.progress = this.progress + currentPageProgress;
          }
          //turn off event loading visual when the api is done being fetched
          this.eventsLoading = false;
        })
        .catch(error => {
          console.log(
            "%cEvents axios error: " + error,
            "color: yellow; font-style: italic; font-weight: 500;padding: 2px; border: 1px solid red;"
          );
        });
    },
    groupBy(objectArray, property) {
      return objectArray.reduce(function(acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    },
    formatDate(date) {
      return moment(date).format("MMM Do YYYY");
    },
    formatTime(date) {
      return moment(date).format("LT");
    }
  },
  /*
   * VUE Life Cycle Hooks
   * https://vuejs.org/v2/guide/instance.html
   */
  beforeCreate() {
    console.log(
      "%cComponent Before Create",
      "color: green; font-weight: 700;padding: 2px"
    );
  },
  created() {
    // run the fetch events method on component create to fetch all events from api
    console.log(
      "%cComponent Created",
      "color: green; font-weight: 700;padding: 2px"
    );
    //this.fetchResources('/api/v2/event_resources.json');
    this.fetchEvents("/api/v2/featured_events.json");
  },
  beforeMount() {
    console.log(
      "%cComponent Before Mount",
      "color: green; font-weight: 700;padding: 2px"
    );
  },
  mounted() {
    console.log(
      "%cComponent Mounted",
      "color: green; font-weight: 700;padding: 2px"
    );
    //Render the calendar on mount
  },
  updated() {
    console.log(process.env.node_env);
    console.log(
      "%cComponent Updated",
      "color: green; font-weight: 700;padding: 2px"
    );
    //var event_6422 = this.eventCalendar.getEventById('6422');

    // all events have been loaded do something
    if (this.progress === 100) {
    }
  }
};
</script>
