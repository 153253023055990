//Import Libraries
import $ from 'jquery';
import 'bootstrap';
import Vue from 'vue';
import objectFitImages from 'object-fit-images';

// Import custom modules
import './polyfill.js';
require('es6-promise').polyfill();
import App from './modules/app.js';

// Import custom components
import EventsCalendar from './components/EventsCalendar.vue';
import FeaturedEvents from './components/FeaturedEvents.vue';


//Initiate Classes Here
const app = new App();


// Run Class Based Scripts Here
function run() {

  app.init();
  app.hamburger(".hamburger");
  app.aos();
  app.search();
  app.tippy_init();
  app.navigationAccordion(".header__navigation--navItemParent > .header__navigation--navLink:first-child[href='#']");
  app.navigationAccordion(".sidebar__navigation--navItemParent > .sidebar__navigation--navLink:first-child[href='#']");
  app.accordion(".component__accordion", ".component__accordion--heading", ".component__accordion--content");
  app.smooth_scroll();

  // if (document.getElementById('rent-slider')) {
  app.rent_slider('.rent-slider');
  // }

  if (document.getElementById('current-exhibitions')) {
    app.current_exhibitions();
  }
  if (document.getElementById('instagramFeed')) {
    app.instagramFeed();
  }
  if (document.querySelector('.exhibitions-slider')) {
    app.exhibitions_slider('.exhibitions-slider--current');
    app.exhibitions_slider('.exhibitions-slider--upcoming');
    app.exhibitions_slider('.exhibitions-slider--past');
  }
  if (document.querySelector('.exhibition-slider')) {
    app.exhibition_slider('.exhibition-slider');
  }
  if (document.querySelector('.js-homepage-slider')) {
    app.homepage_slider('.js-homepage-slider');
  }
  if (document.querySelector('.js-split-slider')) {
    app.homepage_slider('.js-split-slider');
  }
  if (document.querySelector('.js-members-events-slider')) {
    app.member_events_slider('.js-members-events-slider');
  }
  if (document.querySelector('.js-related-events-parent')) {
    app.related_events_modal();
  }
  if (document.getElementById('events-calendar')) {
    const Vue_Calendar = new Vue({
      el: '#events-calendar',
      components: {
        'events-calendar': EventsCalendar
      }
    });
  }
  if (document.getElementById('featured-events')) {
    const Vue_FeaturedEvents = new Vue({
      el: '#featured-events',
      components: {
        'featured-events': FeaturedEvents
      }
    });
  }
  app.navigationAccordion(".sidebar__calendarNavigation--navItemParent > .sidebar__calendarNavigation--navLink:first-child");
  app.bootstrap_tabs('exhibitionsTab');
  objectFitImages();


}


// in case the document is already rendered
if (document.readyState != 'loading') run();
// modern browsers
else if (document.addEventListener) document.addEventListener('DOMContentLoaded', run);
// IE <= 8
else document.attachEvent('onreadystatechange', function() {
  if (document.readyState == 'complete') run();
});